.KeywordDensity {
    font-size: 12px !important;
    font-weight: 500 !important;
    padding-top: 1px;
}

.BoxContainer {
    width: 280px;
    min-width: 100%;
    max-width: 100%;
    height: fit-content;
    overflow-y: auto;
    max-height: 275px;
}

.DensityDetail {
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #80828A !important;
    line-height: 18px !important;
    margin-bottom: 8px !important;
}

.DetailsTextMB {
    color: #646464 !important;
    line-height: 21px !important;
    word-break: break-word;
}

/* .DensityDetailM{
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #80828A !important;
    line-height: 21px;
} */

.TableContainer {
    max-width: 100%;
}

.BoxContainer::-webkit-scrollbar {
    width: 2px !important;
    height: 2px !important;
    border-radius: 2px !important;
}

.BoxContainer::-webkit-scrollbar-track {
    background: #f1f1f1 !important
}

.BoxContainer::-webkit-scrollbar-thumb {
    background: #edca78 !important;
}

.BoxContainer::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}

.TableHeading {
    padding: 4px !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 18px !important;
}

.TableCells {
    font-size: 12px !important;
    font-weight: 600;
    padding: 8px !important;
}

.LimitNotExceedColor {
    color: #0CC683 !important;
}

.LimitExceedColor {
    color: #F93939 !important;
}

/* Card Container Style */

.BottomSheetTitle {
    color: #000 !important;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: -0.028px !important;
    padding-left: 1rem !important;
}

.ContainerM {
    border-radius: 0.625rem;
    background: #FFF;
    box-shadow: 0 3px 10px 0 rgba(240, 242, 245, 0.25);
    max-height: 28rem;
    margin-bottom: 1rem;
    overflow: auto;
    padding: 0px 16px;
    flex-shrink: 0;
}

/* in use */
.KeywordsCardM {
    border-radius: 14px;
    border: 1px solid #e6b540;
    background: #fff;
    margin: 1rem 0rem;
}

.KeywordCardTitleM {
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: -0.028px !important;
}

.BottomBorder {
    border-bottom: 1px solid #e6b540 !important;
}

.KeywordsRecordTitleM {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

.KeywordsRecordTextM {
    color: #80828a !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 100.5% !important;
    padding-top: 6px !important;
}