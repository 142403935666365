.PublishContentText{
    color: #000 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
}

.PublishHistoryText{
    color: #E6B540 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;

}

.SubmittedText{
    width: 20% !important;
}
.RecentText{
    width: 15% !important;
}

.ContentLightTitle{
    color: #71747D;
    text-edge: cap;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 4px;
}

.ContentDark{
    color: var(--background, rgba(0, 0, 0, 0.90));
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.08px;
    margin: 0;
}

.ErrorTitle{
    color: var(--Dark-Gray, var(--Statuses-Inactive, #FF4C05));
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
}

.SkuText{
    color: var(--Black, #2E2E2E);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
    margin-bottom: 6px;
}

.ErrorSubtitle{
    color: var(--Gray, #80828A);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.AmazonErrorButton{
    color: var(--Primary, #E6B540);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    cursor: pointer;
}



.ErrorSubtitle{
    color: var(--Gray, #80828A);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.TooltipText {
    color: #000000;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 18px !important;
}
.Note {
    color: #E6B540;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 18px !important;
}
