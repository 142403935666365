@import url('./assets/css/bootstrap.css');
body {
  background-color: #f5f5f5 !important;
}

code {
  font-family: "Poppins";
}

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px; /* Set the width of the scroll bar */
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the color of the scroll thumb */
  border-radius: 3px; /* Add rounded corners to the scroll thumb */
}


.h8 {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  letter-spacing: -0.002em !important;
  color: #000000 !important;
}

.h8-normal{
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 21px !important;
  letter-spacing: -0.002em !important;
  color: #000000 !important;
}

.cursor-pointer{
  cursor: pointer !important;
}


.slick-dots{
  top: -30px !important;
  height: 30px !important;
}


.primary-fc {
  color: #E6B540 !important;
}

.HoverEffectForButton{
  padding: 6px 6px;
  border-radius: 4px;
}

.HoverEffectForButtonNoPadding{
  border-radius: 4px;
}
.HoverEffectForButtonNoPadding:hover{
  background-color: #FDEFCF !important;
}

.HoverEffectForButton:hover{
  background-color: #FDEFCF;
}

.HoverEffectForLinks:hover{
  text-decoration: underline !important;
}

.w-33{
  width: 33% !important;
}


.rbc-event, .rbc-day-slot .rbc-background-event{
  /*background: #E6B540 !important;*/
}

.rbc-show-more{
  color: #E6B540 !important;
}

.event-text-div{
  font-size: 10px;
}
.event-text-div-mobile{
  font-size: 8px;
}

@media (max-width: 800px) {
  .rbc-show-more{
    font-size: 10px !important;
  }
}

.text-ellipsis {
  white-space: nowrap;        /* Prevent text from wrapping to the next line */
  overflow: hidden;           /* Hide overflow content */
  text-overflow: ellipsis;    /* Show ellipsis (...) when content exceeds the available width */
  max-width: 90px;           /* Limit the width to 100px */
  display: inline-block;            /* Set maximum width for proper ellipsis */
}

/* Screen width greater than 1920px */
@media (max-width: 1800px) {
  .text-ellipsis {
    max-width: 90px;
  }
}



/* Screen width below 1280px */
@media (max-width: 1279px) {
  .text-ellipsis {
    max-width: 50px;
  }
}

/* to override the z-index of row in react big calendar */
.rbc-row-content {
  z-index: 1 !important;
}


.MuiAlert-standardError{
  display: flex !important;
  padding: 6px 16px !important;
  color: rgb(102, 36, 36) !important;
  background-color: rgb(255, 238, 238) !important;
  box-shadow: none !important;
  margin-top: 0 !important;
}