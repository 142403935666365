.Container {
    padding: 2rem;
    width: 100% !important;
    /* height: 60vh !important; */
}

.TableHeading {
    color: #2E2E2E;
    font-style: normal;
    font-weight: 600 !important;
    line-height: normal;
    padding-bottom: 6px;
    background: #F8F9FD !important;
    overflow-y: auto;
}

.TableCells {
    color: #71747D;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.TableCellPrimary {
    color: #E6B540;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    margin: 0;
}

.TableContainer {
    height: 60vh !important;
    overflow-y: auto !important;
    width: 100%;
}

.TableContainer::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
    border-radius: 2px !important;
}

.TableContainer::-webkit-scrollbar-track {
    background: #f1f1f1 !important
}

.TableContainer::-webkit-scrollbar-thumb {
    background: #edca78 !important;
}

.TableContainer::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}

.CopyIcon {
    width: 1.25rem !important;
    height: 1.25rem !important;
    margin-left: 0.25rem;
    color: #737373 !important;
    cursor: pointer;
}


.CellLinesLimitContainer {
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    /* Limit to 2 lines */
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    white-space: normal !important;
    /* Or 'normal' if you want to allow line breaks */
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
}