.BodyContainer {
  border-radius: 0.625rem;
  background: #fff !important;
  box-shadow: 0 3px 10px 0 #f0f2f5 !important;
  gap: 0.625rem;
  margin-top: 0.75rem;
  margin-bottom: 1.25rem;
}

.Heading {
  color: #000 !important;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  letter-spacing: -0.00175rem !important;
}

.Limit {
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1rem !important;
  color: #737373 !important;
}

.InActive {
  background: #ffe5e5 !important;
}

.LimitExceed {
  color: #ea2a33 !important;
}
.CopyIcon {
  width: 1.25rem !important;
  height: 1.25rem !important;
  margin-right: 0.25rem;
  color: #737373 !important;
}
.ShowEditsIcon{
  color: #E6B540 !important;
}

.HeaderButtonContainer{
  height: 40px;
}

.EditBorder{
  border: 1px solid #E6B540
}

.TransparentBorder {
  border: 1px solid transparent;
}

.DisabledText {
  color: #a2a2a2 !important
}