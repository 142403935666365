.CardShell{
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    background: var(--White, #FFF);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.02);
    padding: 20px;
    margin-bottom: 10px;
}


.ContentLightTitle{
    color: #71747D;
    text-edge: cap;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 4px;
}

.ContentDark{
    color: var(--background, rgba(0, 0, 0, 0.90));
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.08px;
    margin: 0;
}

.BorderLeft{
    border-left: 1px solid #D9D9D9;
    padding-left: 16px
}