.Text{
    color: #181818;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin:0;
    margin-left: 10px;
}

.Container{
    display: flex;
    border-radius: 8px;
    background: #EAF3FF;
    align-items: center;
    padding: 14px 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    gap: 8px;
}

.ContainerMobile{
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 8px;
    background: #EAF3FF;
    padding: 14px 20px;
    margin-bottom: 20px;
}

.Button{
    min-width: 102px !important;
}