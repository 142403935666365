.CardContainer{
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    background: #FFEFEF;
}

.Title{
    color: var(--Gray, #80828A);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 160% */
    margin: 0;
}

.ErrorText{
    color: var(--Error, #EA2A33);
    margin: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    cursor: pointer;
}

.SkuTitle{
    color: var(--background, rgba(0, 0, 0, 0.90));
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.08px;
    margin: 0px;
}