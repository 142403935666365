.Title {
    background-color: #697386 !important;
    text-align: center;
    color: white !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    min-width: 350px !important;
    display: flex;
    justify-content: space-between;
}

.BodyContainer {
    width: 528px;
}

.Cancel {
    color: #80828a !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 0.9rem !important;
    line-height: 0.7rem !important;
    cursor: pointer !important;
    margin-right: 1rem !important;
}

.Body {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    line-height: 1.1rem !important;
    color: #333333 !important;
    margin-bottom: 1.2rem !important;
}

.BoxConatiner {
    width: 355px;
    max-width: 100%;
    padding: 20px;
    border-radius: 14px;
}

.SubHeading {
    padding-top: 30px;
    color: #2E2E2E !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 18px !important;

}

.FindVendorCodeText {
    color: #e6b540;
    font-size: 12px !important;
    margin-top: 8px !important;
    text-decoration: underline;
}