.CardContainer {
    margin-top: 15px;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    padding: 30px 33px;
    width: 250px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border-radius: 14px;
    box-shadow: 2px 2px 5px 2px #D8D8D8;
}

.largeCardContainerWidth {
    width: 341px !important;
    padding: 30px 20px !important;
}

.CardContainer:hover {
    border-color: #E6B540 !important;
}

.Title {
    font-size: 16px !important;
    color: #12131A !important;
    line-height: 24px !important;
    padding-top: 13px !important;
    padding-bottom: 10px !important;
}

.Description {
    font-weight: normal !important;
    font-size: 14px !important;
    color: #80828A !important;
    line-height: 21px !important;
}