.Container {
    width: 100%;
    min-width: 700px;
    display: flex;
    flex-direction: column;
}

.ListContainer {
    background: rgba(247, 245, 254, 0.77);
    padding-top: 16px;
    border-radius: 4px;
    margin-bottom: 12px;
    color: rgba(128, 130, 138, 1);
    font-size: 12px;
    line-height: 18px;
}

.ListContainer ol li {
    margin-bottom: 4px;
}


.UploadContainer {
    border: 1px dashed rgba(217, 217, 217, 1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px 0px;
}

.UploadContainerDisable {
    border: 1px dashed rgba(217, 217, 217, 1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px 0px;
    opacity: 0.5;
}

.ClickToUpload {
    color: #E6B540;
    font-weight: 500;
    cursor: pointer;
}

.CsvOrXLS {
    font-size: 14px;
    line-height: 21px;
    color: #80828A;
}

.SampleDownloadContainer {
    display: flex;
    gap: 20px;
    margin-top: 14px;
    color: #E6B540;
}

.UploadedFile {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 21px !important;

}

.FileDetailBox {
    padding: 16px 16px;
    background: #EDF6FE;
    border-radius: 4px;
    margin-top: 14px;
    display: flex;
    align-items: center;
}

.FileDetails {
    flex-grow: 1;
    padding: 0px 22px;
    gap: 4px;
    display: flex;
    flex-direction: column
}

.ErrorMessage {
    font-size: 12px;
    font-weight: 600;
    color: red;
}

.InfoMessage {
    font-size: 12px;
    font-weight: 600;
    color: #e6b540;
}

.Note {
    color: #E6B540;
    font-weight: 500;
    cursor: pointer;
    font-size: 12px !important;
}

.NoteText {
    color: #80828A !important;
    font-size: 12px !important;
    line-height: 14px !important;
    padding-bottom: 16px !important;
}

ol {
    margin-bottom: 6px !important
}