.NoResultTitle{
    color: var(--Black, #2E2E2E) !important;
    text-align: center !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    letter-spacing: -0.04px !important;
}


.NoResultSubTitle{
    color: var(--Gray, #80828A) !important;
    text-align: center !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    width: 50%;
}

.NoResultIcon{
    width: 120px;
    height: 120px;
}