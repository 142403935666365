.cardContainer {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;
    background: #ffffff;
    width: 100%;
    max-width: 800px;
    margin: 0 28px;
    margin-top: 30px;
}

.contentWrapper {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.jsonPre {
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.jsonLine {
    line-height: 24px;
}

.toggleButton {
    display: block;
    margin-top: 8px;
    background: none;
    border: none;
    color: #2196f3;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
}

.toggleButton:hover {
    background: #f5f5f5;
}

.toggleButton:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.3);
}