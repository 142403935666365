.DrawerContainer{
    height: 100%;
    max-height: 100%;
    overflow-y: hidden;
}

.TitleBarWithFilterDrawer {
    width: 100%;
    display: flex;
    height: 90px;
    justify-content: space-between;
    align-items: center;
    padding: 30px 30px;

    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.TitleBarWithFilterDrawerM {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}

.ProdcutEventsTitleDrawer{
    font-size: 18px !important;
    line-height: 27px !important;
    font-weight: 600 !important;
    color: #000000 !important;
}

.Title{
    font-size: 14px !important;
    color: #000000 !important;
    line-height: 21px !important;
    font-weight: 600 !important;
    padding-bottom: 16px;
}

.TitleM{
    font-size: 14px !important;
    color: #000000 !important;
    line-height: 21px !important;
    font-weight: 600 !important;
    padding-bottom: 8px;
}

.EventCardsContainer{
    padding: 30px;
    height: calc(100% - 90px);
    overflow-y: auto;
}

.EventCardsContainerM{
    padding: 16px;
    height: calc(100% - 90px);
    overflow-y: auto;
}

.NoEventsAvailable{
    font-size: 14px !important;
    text-align: center !important;
    color: #80828A !important;
    padding-top: 26px;
    line-height: normal !important;
}
