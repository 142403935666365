.Dot{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #0a53be;
}

.Text{
    font-size: 12px;
    font-weight: 500;
}

.CheckBoxIconFontSize{
    font-size: 32px !important;
}

.TooltipTitle{
    color: var(--Black, #2E2E2E);
    /* Caption/16-Medium */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    margin-bottom: 8px;
}

.TooltipSubTitle{
    color: var(--Black, #2E2E2E);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.TooltipLi{
    color: var(--Gray, #80828A);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.Popper{
    background: white !important;
    color: white;
}

.CloseIcon{
    cursor: pointer;
    font-size: 16px;
}