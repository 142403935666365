.Container{
    padding: 1.5rem 2rem 0.5rem;
}

.SubHeading{
    padding: 0rem 2rem 0.0rem;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #80828A !important;
    line-height: normal !important;
}